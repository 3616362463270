<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="main-wrap">
			<div class="card-list">
				<listItem1 v-for="(item, index) in tableData" :key="index" :itemData="item" :config="listItemConfig">
					<div class="row-footer" slot="footer">
						<button type="button" class="button-plan button-plan-danger" @click="refuseEvent(item)">拒绝</button>
						<button type="button" class="button-plan button-plan-primary" @click="agreeEvent(item)">同意</button>
					</div>
				</listItem1>
			</div>

			<KcEmpty v-if="!tableData.length"></KcEmpty>
		</div>
	</van-pull-refresh>
</template>

<script>
import listItem1 from '@components/listItem/listItem1';
import { getDate } from '@utils/utils.js';
export default {
	name: 'shareSetting',
	components: { listItem1 },
	data() {
		return {
			isRefresh: false,

			listItemConfig: {
				titleKey: 'deviceName',
				layout1: [
					{
						label: '申请人',
						key: 'orderUser'
					},
					{
						label: '申请时间',
						key: 'orderTimeText'
					}
				],
				layout2: [
					{
						label: this.$VUEAPPDEVICECODE(),
						key: 'code'
					},
					{
						label: '卡片号',
						key: 'label'
					},
					{
						label: '设备分类',
						key: 'categoryName'
					},
					{
						label: '品牌',
						key: 'brandName'
					},
					{
						label: '型号',
						key: 'modelName'
					}
				]
			},
			totalCount: 0,
			tableData: []
		};
	},
	created() {
		this.getList();
	},
	mounted() {},
	methods: {
		onRefresh() {
			this.isRefresh = true;
			this.getList(() => {
				setTimeout(() => {
					this.isRefresh = false;
				}, 300);
			});
		},
		getList(callbak) {
			let userInfo = this.$store.getters.userInfo;
			this.$api.deviceOrg
				.shareOrderListByStatus({
					query: '',
					page: 1,
					pageSize: 100,
					tenantId: userInfo.tenantId,
					isTenantIdChild: 1,
					startTime: getDate(new Date().getTime() - 6 * 8.64e7) + ' 00:00:00',
					endTime: getDate() + ' 23:59:59',
					timeType: 1,
					orderStatus: 0
				})
				.then(res => {
					res.data.map(item => {
						item.orderTimeText = this.$filters.formatDateTime(item.orderTime);
					});
					this.tableData = res.data;
					if(callbak) callbak();
				})
				.catch(e => {
					if(callbak) callbak();
				});
		},
		refuseEvent(item) {
			this.$dialog
				.confirm({
					message: '是否拒绝此申请？'
				})
				.then(() => {
					this.$api.deviceWorkManager
						.deviceShareApprove({
							deviceId: item.deviceId,
							approve: 2,
							orderId: item.id
						})
						.then(res => {
							this.getList()
							this.$toast.success('已拒绝');
              localStorage.setItem('allDeviceUpdate', new Date().getTime())
              this.onRefresh();
            })
						.catch(e => {});
				})
				.catch(() => {});
		},
		agreeEvent(item) {
			this.$dialog
				.confirm({
					message: '是否同意此申请？'
				})
				.then(() => {
					this.$api.deviceWorkManager
						.deviceShareApprove({
							deviceId: item.deviceId,
							approve: 1,
							orderId: item.id
						})
						.then(res => {
							this.getList()
							this.$toast.success('已同意');
              localStorage.setItem('allDeviceUpdate', new Date().getTime())
              this.onRefresh();
            })
						.catch(e => {});
				})
				.catch(() => {});
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
@import '@styles/layout.scss';
.row-footer {
	justify-content: flex-end;
}
</style>
